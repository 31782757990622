import { type ReactNode, useState, useCallback, useMemo } from 'react';

import * as Collapsible from '@radix-ui/react-collapsible';

import { styled } from '@parsec/styles';

import { AppRuleLabel } from '../AppRuleLabel';
import Icon from '../Icon';

import collapsibleStyles from './appRuleFieldsetCollapsible.module.css';

// STYLES
const ArrowIcon = styled(Icon, {
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const CollapsibleRoot = styled(Collapsible.Root, {
  width: '100%',
  borderTop: '0.1rem solid $pancham',
  paddingTop: '3.2rem',
  fontFamily: '$newDefault',
  variants: {
    isSubFieldset: {
      true: {
        border: 'none',
        padding: '0'
      }
    }
  }
});

const CollapsibleTrigger = styled(Collapsible.Trigger, {
  fontFamily: '$newDefault',
  width: 'fit-content',
  fontSize: '$newInfo',
  lineHeight: '$info',
  color: '$consoleWhite',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  cursor: 'pointer',
  gridArea: 'button',
  justifySelf: 'end'
});

const CollapsibleContent = styled(Collapsible.Content, {
  overflow: 'hidden'
});

const ContentWrapper = styled('div', {
  padding: '3.2rem $small 0 0',
  variants: {
    isSubFieldset: {
      true: {
        paddingTop: '$small'
      }
    }
  }
});

const Wrapper = styled('div', {
  display: 'grid',
  rowGap: '$large',
  gridTemplateColumns: 'auto auto 7.6rem',
  gridTemplateAreas:
    "'legend labels button' 'description description description'"
});

const Legend = styled('legend', {
  fontSize: '$body',
  lineHeight: '$attribution',
  fontWeight: '$bold',
  gridArea: 'legend',
  textTransform: 'capitalize',
  display: 'flex',
  gap: '$large',
  variants: {
    isSubFieldset: {
      true: {
        fontSize: '$newBody',
        fontWeight: '$normal'
      }
    }
  }
});

const LabelsWrapper = styled('div', {
  gridArea: 'labels',
  display: 'flex',
  gap: '$medium'
});

const Span = styled('span', {
  display: 'flex',
  alignItems: 'center',
  gap: '$small',
  fontSize: '$newInfo',
  lineHeight: '$info',
  color: '$rhyhorn'
});

const LockIcon = styled(Icon, {
  width: '1.6rem',
  height: '1.6rem'
});

const Description = styled('p', {
  fontSize: '$newInfo',
  lineHeight: '$info',
  gridArea: 'description'
});

type EnabledOpts = {
  isSAMLEnabled?: boolean;
  isTFAEnabled?: boolean;
};

export interface AppRuleFieldsetProps extends Collapsible.CollapsibleProps {
  children: ReactNode;
  formId: string;
  title: string;
  description?: string;
  isDisabled?: boolean;
  isSubFieldset?: boolean;
  disableText?: string;
  enabledOptions?: EnabledOpts;
  numError?: number;
}

export function AppRuleFieldsetCollapsible({
  children,
  formId,
  title,
  description,
  defaultOpen = false,
  isDisabled = false,
  disableText = '',
  isSubFieldset = false,
  enabledOptions = { isSAMLEnabled: false, isTFAEnabled: false },
  numError = 0,
  onOpenChange
}: AppRuleFieldsetProps) {
  const { isSAMLEnabled, isTFAEnabled } = enabledOptions;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      onOpenChange?.(open);
      setIsOpen(open);
    },
    [onOpenChange]
  );

  const errorLabel = useMemo(() => {
    if (numError === 1) {
      return '1 Error';
    } else {
      return `${numError} Errors`;
    }
  }, [numError]);

  return (
    <CollapsibleRoot
      open={isOpen}
      onOpenChange={handleOpenChange}
      isSubFieldset={isSubFieldset}
    >
      <fieldset form={formId} name={title}>
        <Wrapper>
          <Legend isSubFieldset={isSubFieldset}>
            {title}
            {isDisabled ? (
              <Span>
                <LockIcon name="lockKey" />
                {disableText}
              </Span>
            ) : null}
            <LabelsWrapper>
              {isSAMLEnabled ? (
                <AppRuleLabel type="notice" text="SAML Enabled" />
              ) : null}
              {isTFAEnabled ? (
                <AppRuleLabel type="notice" text="2FA Enabled" />
              ) : null}
              {numError > 0 ? (
                <AppRuleLabel type="error" text={errorLabel} />
              ) : null}
            </LabelsWrapper>
          </Legend>
          <CollapsibleTrigger>
            {isOpen ? 'Collapse' : 'Expand'}
            <ArrowIcon name="caret" open={isOpen} />
          </CollapsibleTrigger>
          {description ? <Description>{description}</Description> : null}
        </Wrapper>
        <CollapsibleContent className={collapsibleStyles.CollapsibleContent}>
          <ContentWrapper isSubFieldset={isSubFieldset}>
            {children}
          </ContentWrapper>
        </CollapsibleContent>
      </fieldset>
    </CollapsibleRoot>
  );
}
