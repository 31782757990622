// @parsec
import { accessLinkMetaData } from '@parsec/kessel';

import KeyFactory from './KeyFactory';
import { useKessel } from './Provider';
import { useQuery } from './useQuery';
import { useQueryData } from './useQueryData';
import { useWrapError } from './useWrapError';

const key = new KeyFactory('access_link_metadata');

export function useAccessLinkMetaData() {
  return useQueryData(key.all(), accessLinkMetaData);
}

const DEFAULT_OPTIONS = {
  enabled: true
};

export function useGetAccessLinkMetaData(
  vars: { accessLinkId: string },
  options = DEFAULT_OPTIONS
) {
  const kessel = useKessel();
  const enabled = vars.accessLinkId !== '' && options.enabled;

  const result = useQuery(
    key.detail(vars),
    async function queryFn(ctx) {
      const req = key.vars(ctx.queryKey);

      const res =
        await kessel.accessLinkPublicData.getAccessLinkPublicData(req);
      return res.body.data;
    },
    { enabled }
  );

  const error = useWrapError(result.error, {
    error: "Couldn't get access link data."
  });
  return { ...result, error };
}
