import { styled } from '@parsec/styles';

import Icon from '../Icon';

const LabelWrapper = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  width: 'fit-content',
  height: '2.6rem',
  columnGap: '$small',
  alignItems: 'center',
  borderWidth: '0.1rem',
  borderRadius: '$medium',
  borderStyle: 'solid',
  padding: '0 $medium',
  variants: {
    type: {
      notice: {
        borderColor: '$success500'
      },
      error: {
        borderColor: '$error500'
      },
      warning: {
        borderColor: '$warning500'
      }
    }
  }
});

const StyledIcon = styled(Icon, {
  width: '1.6rem',
  height: '1.6rem',
  variants: {
    type: {
      notice: {
        color: '$success500'
      },
      error: {
        color: '$error500'
      },
      warning: {
        color: '$warning500'
      }
    }
  }
});

const Text = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  lineHeight: '$info'
});

interface AppRuleLabelProps {
  text: string;
  type: 'notice' | 'warning' | 'error';
}

export function AppRuleLabel({ text, type }: AppRuleLabelProps) {
  return (
    <LabelWrapper type={type}>
      <StyledIcon
        type={type}
        name={type === 'notice' ? 'checkedShield' : 'warningSign'}
      />
      <Text>{text}</Text>
    </LabelWrapper>
  );
}
