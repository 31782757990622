import type { ButtonVariants } from '../Button';

import { IconNames } from '../Icon';

export const STATUS = {
  AccountPaused: 'account_paused',
  AccountCancelling: 'account_cancelling',
  AccountCancelled: 'account_cancelled',
  AccountSuspended: 'account_suspended',
  AccountOverdue: 'account_overdue',
  ChangesPending: 'changes_pending',
  UpToDate: 'up_to_date',
  PaymentProcessingError: 'payment_processing_error',
  ActiveTrial: 'active_trial',
  ActiveTrialCancelled: 'active_trial_cancelled',
  NewInvoiceAvailable: 'new_invoice_available'
} as const;

export type StatusValue = (typeof STATUS)[keyof typeof STATUS];

export type StatusCopyType = {
  [key in StatusValue]: {
    title: string;
    body: string;
    iconName: IconNames;
    ctaText?: string;
    ctaIconName?: IconNames;
    buttonVariant?: ButtonVariants;
  };
};

export const STATUS_COPY: StatusCopyType = {
  [STATUS.NewInvoiceAvailable]: {
    title: 'New Invoice Available',
    body: 'You have new invoices posted to your account and available for payment. Review your invoices and billing information for details.',
    iconName: 'invoiceAvailable',
    ctaIconName: 'eye',
    ctaText: 'View Invoices',
    buttonVariant: { kind: 'dark' }
  },
  [STATUS.ChangesPending]: {
    title: 'Changes Pending',
    body: 'Your subscription is scheduled to change at your next renewal. Review your subscription for details.',
    iconName: 'warning',
    ctaText: 'Revert Changes',
    ctaIconName: 'revertArrow',
    buttonVariant: { kind: 'dark' }
  },
  [STATUS.UpToDate]: {
    title: 'Account is up-to-date',
    body: 'Your account, billing, and payments are up-to-date. Your renewal is schedules with no changes.',
    iconName: 'checkFilled'
  },
  [STATUS.AccountOverdue]: {
    title: 'Account Overdue',
    body: 'You have an overdue invoice in your account. Please review your billing and payment details to bring your account up-to-date.',
    iconName: 'exBox',
    ctaText: 'View Invoices',
    ctaIconName: 'eye',
    buttonVariant: { kind: 'white' }
  },
  [STATUS.AccountCancelling]: {
    title: 'Account Cancelling',
    body: 'Your subscription will cancel immediately after this period ends. You will lose access to your team.',
    iconName: 'ban',
    ctaText: 'Reactivate Account',
    ctaIconName: 'redoCheck',
    buttonVariant: { kind: 'white' }
  },
  [STATUS.AccountCancelled]: {
    title: 'Account Cancelled',
    body: 'Your subscription is cancelled. Your team members can no longer make connections. You will soon lose access to your team.',
    iconName: 'ban',
    ctaText: 'Reactivate Account',
    ctaIconName: 'redoCheck',
    buttonVariant: { kind: 'dark' }
  },
  [STATUS.AccountSuspended]: {
    title: 'Account Suspended',
    body: 'Your subscription will pause immediately after this period ends. Your team will be unable to make connections, but you’ll still have access to your account.',
    iconName: 'pauseCircle',
    ctaText: 'Reactivate Account',
    ctaIconName: 'redoCheck',
    buttonVariant: { kind: 'dark' }
  },
  [STATUS.PaymentProcessingError]: {
    title: `Can't process payment`,
    body: 'We recently tried to process a payment, but your billing information is out of date. Please update your billing information.',
    iconName: 'warning',
    ctaText: 'Update Billing Information',
    ctaIconName: 'billFilled',
    buttonVariant: { kind: 'white' }
  },
  [STATUS.ActiveTrial]: {
    title: 'Trial Active',
    body: 'Your subscription is scheduled to start immediately after your trial period ends.',
    iconName: 'clock',
    ctaText: 'Cancel Trial',
    ctaIconName: 'exCircle',
    buttonVariant: { kind: 'error' }
  },
  [STATUS.ActiveTrialCancelled]: {
    title: 'Trial Cancelling',
    body: 'Your subscription is scheduled to cancel immediately after your trial period ends.',
    iconName: 'clock',
    ctaText: 'Reactivate Trial',
    ctaIconName: 'progress',
    buttonVariant: { kind: 'success' }
  },
  [STATUS.AccountPaused]: {
    title: 'Account Paused',
    body: 'Your subscription will pause immediately after this period ends. Your team will be unable to make connections, but you’ll still have access to your account.',
    iconName: 'pauseCircle',
    ctaText: 'Reactivate Account',
    ctaIconName: 'redoCheck',
    buttonVariant: { kind: 'dark' }
  }
};
