import { forwardRef } from 'react';

import {
  type TurnstileInstance,
  type TurnstileProps,
  Turnstile
} from '@marsidev/react-turnstile';

import { captureException, captureMessage } from '@parsec/sentry';
import { CSS, styled } from '@parsec/styles';

import { CaptchaActions } from './types';

const StyledTurnstile = styled(Turnstile, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export interface CaptchaWidgetProps extends TurnstileProps {
  action: CaptchaActions;
  css?: CSS;
}

const sentryContext = {
  tags: { component: 'CaptchaWidget' }
};

export const CaptchaWidget = forwardRef<TurnstileInstance, CaptchaWidgetProps>(
  function Widget(
    {
      action,
      className,
      css,
      id,
      onError: onCaptchaError,
      onExpire: onCaptchaExpire,
      onTimeout: onCaptchaTimeout,
      onWidgetLoad,
      options,
      siteKey,
      style,
      ...rest
    },
    ref
  ) {
    // TODO: Uncomment this when we have a better pattern of errors from the new library
    // https://developers.cloudflare.com/turnstile/troubleshooting/client-side-errors/#error-codes
    // const IGNORED_TURNSTILE_ERROR_CODES = new Set<string>([
    //   // Error codes indicating an automated device / bot
    //   '106010',
    //   '300010',
    //   '300030',
    //   '300031',
    //   '600010',
    //   // Error code indicating the visitor took too long to solve the challenge
    //   '110600'
    // ]);

    const onError = (error: string) => {
      const reformedError = new Error(`Turnstile Error: ${error}`);

      // Since we're swapping the library out, we're going to monitor error codes to see if there's an influx of new ones
      if (typeof error !== 'string') {
        captureException(reformedError, sentryContext);
      }

      if (onCaptchaError) {
        onCaptchaError(error);
      }
    };

    const onExpire = (token: string) => {
      captureMessage('Turnstile: token expired', sentryContext);

      if (onCaptchaExpire) {
        onCaptchaExpire(token);
      }
    };

    const onTimeout = () => {
      captureMessage('Turnstile: timeout, challenge expired', sentryContext);

      if (onCaptchaTimeout) {
        onCaptchaTimeout();
      }
    };

    const onUnsupported = () => {
      captureMessage('Turnstile: browser unsupported', sentryContext);
    };

    return (
      <StyledTurnstile
        siteKey={siteKey}
        id={id}
        ref={ref}
        options={{ action, theme: 'dark', execution: 'render', ...options }}
        className={className}
        style={style}
        onWidgetLoad={onWidgetLoad}
        onError={onError}
        onExpire={onExpire}
        onTimeout={onTimeout}
        onUnsupported={onUnsupported}
        css={css}
        {...rest}
      />
    );
  }
);
