import * as time from '@parsec/time';

export const NOT_FOUND_STATUS = 404;
export const FORBIDDEN_STATUS = 403;
export const OK_STATUS = 200;

// This is the default state, no maintenance status
export const ACCEPTABLE_STATUSES = [NOT_FOUND_STATUS, FORBIDDEN_STATUS];

const DEFAULT_POLL_HOURS = 6;
export const DEFAULT_POLL_TIME = DEFAULT_POLL_HOURS * time.HOUR; // 6 hours default poll time
