import { useCallback, useEffect } from 'react';

import { useInterval } from '@parsec/hooks';
import { captureException } from '@parsec/sentry';

import { ACCEPTABLE_STATUSES, OK_STATUS } from './constants';

interface PollApiIntervalProps<T> {
  url: string;
  delay: number | null;
  onStatusAvailable: (result: T) => void;
}

export function usePollApiInterval<T>({
  url,
  delay,
  onStatusAvailable
}: PollApiIntervalProps<T>) {
  const getMaintenanceStatus = useCallback(
    async (signal?: AbortSignal) => {
      if (!url) {
        return;
      }

      try {
        // avoiding useQuery for now to reduce config overhead in this library
        const response = await fetch(url, { signal });
        if (ACCEPTABLE_STATUSES.includes(response.status)) {
          // no maintenance status available, default state
        } else if (response.status === OK_STATUS) {
          // maintanence status is available
          const data: T = await response.json();
          onStatusAvailable(data);
        }
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          // Aborting a fetch throws an error
          // So we can't update state afterwards
        } else {
          captureException(error, {
            tags: { component: 'usePollApiInterval', url }
          });
        }
      }
    },
    [onStatusAvailable, url]
  );

  useEffect(() => {
    const abortController = new AbortController();

    const initialFetch = async () => {
      await getMaintenanceStatus(abortController.signal);
    };

    initialFetch();

    return () => {
      abortController.abort();
    };
    // only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    await getMaintenanceStatus();
  }, delay);
}
