import type { ReactNode, ButtonHTMLAttributes } from 'react';

import { styled, Button, AppRuleLabel } from '@parsec/components';

import { useWarnIfUnsavedChanges } from 'lib/hooks';

interface ResetButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isResetDisabled?: boolean;
  onReset: () => void;
  children: ReactNode;
}

function ResetButton(props: ResetButtonProps) {
  const { isResetDisabled, onReset, ...rest } = props;
  return (
    <Button
      level="secondary"
      disabled={isResetDisabled}
      onClick={onReset}
      version="newFont"
      {...rest}
    >
      {props.children}
    </Button>
  );
}

interface SaveButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isSaveDisabled?: boolean;
  onSave: () => void;
  children: ReactNode;
}

function SaveButton(props: SaveButtonProps) {
  const { isSaveDisabled, onSave, ...rest } = props;
  return (
    <Button
      version="newFont"
      disabled={isSaveDisabled}
      level="primary"
      kind="neutral"
      onClick={onSave}
      {...rest}
    >
      {props.children}
    </Button>
  );
}

export interface SaveChangesPromptProps {
  message?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  errorText?: string;
  children: ReactNode;
}
export function SaveChangesPrompt(props: SaveChangesPromptProps) {
  const { message, isDisabled, children, hasError, errorText = '' } = props;

  useWarnIfUnsavedChanges(!isDisabled, () => {
    return confirm('Changes you made may not be saved.');
  });

  return (
    <UpdateWrapper>
      <GridWrapper>
        <Message disabled={isDisabled}>{message}</Message>
        {hasError ? <AppRuleLabel type="error" text={errorText} /> : null}
      </GridWrapper>
      <GridWrapper>{children}</GridWrapper>
    </UpdateWrapper>
  );
}

SaveChangesPrompt.ResetButton = ResetButton;
SaveChangesPrompt.SaveButton = SaveButton;
export default SaveChangesPrompt;

const Message = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  variants: {
    disabled: {
      true: {
        color: '$error500'
      }
    }
  }
});

const UpdateWrapper = styled('div', {
  position: 'sticky',
  zIndex: 2,
  bottom: '-8rem',
  left: 0,
  borderRadius: '$medium',
  backgroundColor: '#0C0C0D',
  padding: '$xxlarge',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
  width: 'calc(100% + 1.6rem)',
  marginLeft: '-0.8rem'
});

const GridWrapper = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$large',
  alignItems: 'center'
});
