// libraries
import * as n from 'narrows';

/******************************************************************************
 * Response Body
 ******************************************************************************/

export const data = <T>(validator: n.Validator<T>) =>
  n.record({ data: validator });

export const paginatedData = <T>(validator: n.Validator<T>) =>
  n.record({ data: n.array(validator), count: n.number });

/******************************************************************************
 * Session
 ******************************************************************************/

export const session = n.record({
  id: n.string,
  user_id: n.optional(n.number)
});

export type Session = n.TypeOf<typeof session>;

/******************************************************************************
 * Auth
 ******************************************************************************/

export const auth = n.record({
  session_id: n.string,
  user_id: n.number
});

export type Auth = n.TypeOf<typeof auth>;

/******************************************************************************
 * Card
 ******************************************************************************/

export const card = n.record({
  first_name: n.string,
  last_name: n.string,
  last4: n.string,
  expiry_month: n.number,
  expiry_year: n.number,
  zip: n.string,
  country: n.string
});

export type Card = n.TypeOf<typeof card>;

/******************************************************************************
 * Customer
 ******************************************************************************/

export const customer = n.record({
  first_name: n.optional(n.string),
  last_name: n.optional(n.string),
  email: n.string,
  phone: n.optional(n.string),
  company: n.optional(n.string),
  tax_id: n.optional(n.string),
  tax_exempt_number: n.optional(n.string),
  card_status: n.optional(n.string),
  billing_address: n.record({
    line1: n.string,
    line2: n.optional(n.string),
    city: n.string,
    state: n.string,
    zip: n.optional(n.string),
    country: n.string
  }),
  invoice_config: n.record({
    net_term_days: n.number,
    auto_collection: n.boolean
  })
});

export type Customer = n.TypeOf<typeof customer>;

/******************************************************************************
 * Team
 ******************************************************************************/

export const team = n.record({
  id: n.string,
  alias: n.optional(n.nullable(n.string)),
  user_id: n.number,
  name: n.string,
  num_members: n.number,
  num_invites: n.number,
  is_active: n.boolean,
  has_machine_key: n.optional(n.boolean),
  capabilities: n.record({
    standard_seats: n.number,
    free_seats: n.number,
    total_seats: n.number,
    features: n.record({
      is_relay_enabled: n.boolean,
      is_team_api_enabled: n.boolean,
      is_custom_roles_enabled: n.boolean,
      is_billing_enabled: n.boolean,
      is_self_serve_enabled: n.boolean,
      is_seat_downgrade_enabled: n.boolean
    }),
    settings: n.record({
      is_scim_enabled: n.boolean,
      is_consumer_connection_blocked: n.boolean,
      is_new_saml_domain_enabled: n.boolean
    }),
    release_toggles: n.record({
      rt_hpr_v2: n.boolean,
      rt_app_rule_v2: n.boolean,
      rt_new_ui: n.boolean
    })
  }),
  is_using_new_saml_domain: n.boolean
});

export type Team = n.TypeOf<typeof team>;

/******************************************************************************
 * Team Non Compliant Users
 ******************************************************************************/

export const teamNonCompliantUsers = n.record({
  count: n.number
});

export type TeamNonCompliantUsers = n.TypeOf<typeof teamNonCompliantUsers>;

/******************************************************************************
 * Public Team Invite
 ******************************************************************************/

export const publicTeamInvite = n.record({
  team_name: n.string,
  account_exists: n.boolean,
  requires_tfa: n.boolean
});

export type PublicTeamInvite = n.TypeOf<typeof publicTeamInvite>;

/******************************************************************************
 * Subscription
 ******************************************************************************/

export const warpSubscription = n.record({
  plan_id: n.any(n.literal('warp-yearly'), n.literal('warp-monthly')),
  plan_unit_price_cents: n.number,
  plan_amount_cents: n.number,
  plan_quantity: n.number,
  billing_interval: n.any(n.literal('month'), n.literal('year')),
  current_term_end: n.nullable(n.string),
  trial_end: n.nullable(n.string),
  trial_end_activation_enabled: n.boolean,
  cancelled_at: n.nullable(n.string),
  status: n.any(
    n.literal('future'),
    n.literal('in_trial'),
    n.literal('active'),
    n.literal('non_renewing'),
    n.literal('paused'),
    n.literal('cancelled')
  ),
  po_number: n.optional(n.string),
  has_scheduled_changes: n.boolean,
  due_invoices_count: n.number,
  due_since: n.nullable(n.string),
  addons: n.nullable(
    n.array(
      n.record({
        id: n.string,
        quantity: n.number
      })
    )
  ),
  coupon: n.string
});

const _teamSub = n.record({
  plan_id: n.any(
    n.literal('team-yearly'),
    n.literal('team-monthly'),
    n.literal('team-enterprise-monthly'),
    n.literal('team-enterprise-yearly')
  ),
  plan_unit_price_cents: n.number,
  plan_amount_cents: n.number,
  plan_quantity: n.number,
  billing_interval: n.any(n.literal('month'), n.literal('year')),
  current_term_end: n.nullable(n.string),
  trial_end: n.nullable(n.string),
  trial_end_activation_enabled: n.boolean,
  cancelled_at: n.nullable(n.string),
  status: n.any(
    n.literal('future'),
    n.literal('in_trial'),
    n.literal('active'),
    n.literal('non_renewing'),
    n.literal('paused'),
    n.literal('cancelled')
  ),
  po_number: n.optional(n.string),
  has_scheduled_changes: n.boolean,
  due_invoices_count: n.number,
  due_since: n.nullable(n.string),
  addons: n.nullable(
    n.array(
      n.record({
        id: n.string,
        quantity: n.number
      })
    )
  ),
  coupon: n.string
});

export const teamSubscription = n.all(
  _teamSub,
  n.record({
    with_changes: n.optional(_teamSub)
  })
);

export type WarpSubscription = n.TypeOf<typeof warpSubscription>;
export type TeamSubscription = n.TypeOf<typeof teamSubscription>;

/******************************************************************************
 * Team SAML
 ******************************************************************************/

export const saml = n.record({
  sso_url: n.string,
  entity: n.string,
  certificate: n.nullable(n.string)
});

export type SAML = n.TypeOf<typeof saml>;

/******************************************************************************
 * Team Group
 ******************************************************************************/
const permittedGroupPermission = n.record({
  show_group_assigned_managed_hosts: n.boolean,
  show_user_assigned_managed_hosts: n.boolean,
  show_personal_hosts: n.boolean
});

export const teamGroup = n.all(
  n.record({
    id: n.number,
    name: n.string,
    team_id: n.string,
    is_scim: n.boolean,
    team_machine_count: n.number,
    team_member_count: n.number
  }),
  permittedGroupPermission
);

const teamGroupPermission = n.all(
  n.record({
    host_group_id: n.number,
    client_group_id: n.number
  }),
  permittedGroupPermission
);

export const permittedTeamGroup = n.record({
  id: n.number,
  name: n.string,
  team_machine_count: n.number,
  team_member_count: n.number,
  incoming_permissions: teamGroupPermission,
  outgoing_permissions: teamGroupPermission
});

export type TeamGroup = n.TypeOf<typeof teamGroup>;

export type PermittedTeamGroup = n.TypeOf<typeof permittedTeamGroup>;

export type PermittedGroupPermission = n.TypeOf<
  typeof permittedGroupPermission
>;

export const teamGroupData = data(teamGroup);

export type TeamGroupData = n.TypeOf<typeof teamGroupData>;

export const paginatedTeamGroupData = paginatedData(teamGroup);

export type PaginatedTeamGroupData = n.TypeOf<typeof paginatedTeamGroupData>;

/******************************************************************************
 * App Config
 ******************************************************************************/

export const appConfig = n.object(
  n.record({
    value: n.any(n.boolean, n.string, n.number)
  })
);

export type AppConfig = n.TypeOf<typeof appConfig>;

/******************************************************************************
 * Team & Group App Config
 ******************************************************************************/

export const teamAppConfig = n.record({
  app_arcade: n.optional(n.nullable(n.boolean)),
  app_friends: n.optional(n.nullable(n.boolean)),
  app_watermark: n.optional(n.nullable(n.string)),
  use_team_ws: n.optional(n.nullable(n.boolean)),
  app_copy_paste: n.optional(n.nullable(n.boolean)),
  host_privacy_mode: n.optional(n.nullable(n.boolean)),
  host_privacy_kick: n.optional(n.nullable(n.boolean)),
  host_virtual_monitors: n.optional(n.nullable(n.number)),
  host_idle_kick_time: n.optional(n.nullable(n.string)),
  app_can_logout: n.optional(n.nullable(n.boolean)),
  machines_can_logout: n.optional(n.nullable(n.boolean)),
  app_stun_address: n.optional(n.nullable(n.array(n.string))),
  app_client_stun_address: n.optional(n.nullable(n.array(n.string))),
  use_latest_release: n.optional(n.nullable(n.boolean)),
  network_fast_relay_ping: n.optional(n.nullable(n.boolean)),
  network_server_start_port: n.optional(n.nullable(n.number)),
  network_client_start_port: n.optional(n.nullable(n.number)),
  host_full_fps: n.optional(n.nullable(n.boolean)),
  app_daisychaining: n.optional(n.nullable(n.number)),
  client_web_ui: n.optional(n.nullable(n.boolean))
});

export type TeamAppConfig = n.TypeOf<typeof teamAppConfig>;

// TEAM APP CONFIG V2

const booleanRecord = n.any(
  n.record({ value: n.nullable(n.boolean) }),
  n.record({ default: n.nullable(n.boolean) })
);

const stringRecord = n.any(
  n.record({ value: n.nullable(n.string) }),
  n.record({ default: n.nullable(n.string) })
);

const stringArrayRecord = n.any(
  n.record({ value: n.nullable(n.array(n.string)) }),
  n.record({ default: n.nullable(n.array(n.string)) })
);

const numberRecord = n.any(
  n.record({ value: n.nullable(n.number) }),
  n.record({ default: n.nullable(n.number) })
);

export const teamAppConfigV2 = n.record({
  app_arcade: n.optional(booleanRecord),
  app_friends: n.optional(booleanRecord),
  app_watermark: n.optional(stringRecord),
  use_team_ws: n.optional(booleanRecord),
  app_copy_paste: n.optional(booleanRecord),
  host_privacy_mode: n.optional(booleanRecord),
  host_privacy_kick: n.optional(booleanRecord),
  host_virtual_monitors: n.optional(numberRecord),
  host_idle_kick_time: n.optional(stringRecord),
  app_can_logout: n.optional(booleanRecord),
  machines_can_logout: n.optional(booleanRecord),
  app_stun_address: n.optional(stringArrayRecord),
  app_client_stun_address: n.optional(stringArrayRecord),
  use_latest_release: n.optional(booleanRecord),
  network_fast_relay_ping: n.optional(booleanRecord),
  network_server_start_port: n.optional(numberRecord),
  network_client_start_port: n.optional(numberRecord),
  host_full_fps: n.optional(booleanRecord),
  app_daisychaining: n.optional(numberRecord),
  client_web_ui: n.optional(booleanRecord),
  client_decoder_h265: n.optional(booleanRecord),
  network_raw_audio: n.optional(booleanRecord),
  encoder_bitrate: n.optional(numberRecord),
  encoder_min_qp: n.optional(numberRecord),
  host_lock_desktop: n.optional(booleanRecord),
  host_protective_mode: n.optional(booleanRecord)
});

export type TeamAppConfigV2 = n.TypeOf<typeof teamAppConfigV2>;

/******************************************************************************
 * App Rules
 ******************************************************************************/

export const appRule = n.record({
  id: n.string,
  team_id: n.string,
  name: n.string,
  is_default: n.boolean,
  team_member_count: n.number,
  team_machine_count: n.number,
  app_config: teamAppConfig,
  app_config_v2: teamAppConfigV2,
  enforce_saml: n.nullable(n.boolean),
  enforce_tfa: n.nullable(n.boolean),
  enforce_ip_verification: n.nullable(n.boolean),
  client_scope_expiry: n.nullable(n.number),
  force_team_computer_rules: n.nullable(n.boolean),
  network_fast_relay_ping: n.optional(n.nullable(n.boolean)),
  network_server_start_port: n.optional(n.nullable(n.number)),
  network_client_start_port: n.optional(n.nullable(n.number))
});

export type AppRule = n.TypeOf<typeof appRule>;

export const appRuleData = data(appRule);

export type AppRuleData = n.TypeOf<typeof appRuleData>;

export const paginatedAppRuleData = paginatedData(appRule);

export type PaginatedAppRuleData = n.TypeOf<typeof paginatedAppRuleData>;

/******************************************************************************
 * Team Member
 ******************************************************************************/

export const teamMember = n.record({
  user_id: n.number,
  team_id: n.string,
  team_group_ids: n.optional(n.array(n.number)),
  tag: n.string,
  is_saml: n.boolean,
  is_scim: n.boolean,
  is_active: n.boolean,
  last_connected_at: n.nullable(n.string),
  tfa_enabled: n.boolean,
  user: n.record({
    name: n.string,
    email: n.string,
    id: n.number,
    avatar_url: n.string,
    is_confirmed: n.boolean
  }),
  role_ids: n.array(n.string),
  team_app_rule: appRule
});

export type TeamMember = n.TypeOf<typeof teamMember>;

export const teamMemberData = data(teamMember);

export type TeamMemberData = n.TypeOf<typeof teamMemberData>;

export const paginatedTeamMemberData = paginatedData(teamMember);

export type PaginatedTeamMemberData = n.TypeOf<typeof paginatedTeamMemberData>;

/******************************************************************************
 * Team Invite
 ******************************************************************************/

export const teamInvite = n.record({
  team_id: n.string,
  email: n.string,
  expires_at: n.string,
  updated_at: n.string,
  created_at: n.string,
  team_group_ids: n.optional(n.array(n.number))
});

export type TeamInvite = n.TypeOf<typeof teamInvite>;

export const teamInviteData = data(teamInvite);

export type TeamInviteData = n.TypeOf<typeof teamInviteData>;

export const paginatedTeamInviteData = paginatedData(teamInvite);

export type PaginatedTeamInviteData = n.TypeOf<typeof paginatedTeamInviteData>;

/******************************************************************************
 * User
 ******************************************************************************/

export const user = n.record({
  id: n.number,
  name: n.string,
  email: n.string,
  warp: n.boolean,
  staff: n.boolean,
  team_id: n.string,
  is_saml: n.boolean,
  has_tfa: n.boolean,
  avatar_nonce: n.optional(n.string),
  marketing_opt_in: n.nullable(n.boolean),
  attribution_data: n.optional(n.object(n.any(n.object(n.string), n.string))),
  is_out_of_compliance: n.boolean
});

export type User = n.TypeOf<typeof user>;

/******************************************************************************
 * Email Verification
 ******************************************************************************/
export const verification = n.record({
  confirmed: n.boolean
});

/******************************************************************************
 * Guest
 ******************************************************************************/

export const guest = n.record({
  user_id: n.number,
  guest_id: n.number,
  keyboard: n.boolean,
  gamepad: n.boolean,
  mouse: n.boolean
});

export type Guest = n.TypeOf<typeof guest>;

/******************************************************************************
 * Host
 ******************************************************************************/

export const host = n.record({
  user: n.record({
    id: n.number,
    name: n.string
  }),
  peer_id: n.string,
  build: n.string,
  description: n.string,
  max_players: n.number,
  mode: n.any(n.literal('desktop'), n.literal('game')),
  name: n.string,
  players: n.number,
  public: n.boolean
});

export type Host = n.TypeOf<typeof host>;

export const hostData = data(host);

export type HostData = n.TypeOf<typeof hostData>;

export const paginatedHostData = paginatedData(host);

export type PaginatedHostData = n.TypeOf<typeof paginatedHostData>;

/******************************************************************************
 * Machine
 ******************************************************************************/

export const machine = n.record({
  id: n.string,
  peer_id: n.string,
  name: n.string,
  is_online: n.boolean,
  is_guest_access: n.boolean,
  is_managed: n.boolean,
  user: n.nullable(
    n.record({
      id: n.number,
      email: n.string
    })
  ),
  res_email: n.string,
  team_groups: n.optional(
    n.array(
      n.record({
        id: n.number,
        name: n.string
      })
    )
  ),
  guests: n.array(guest),
  team_app_rule: appRule
});

export type Machine = n.TypeOf<typeof machine>;

export const machineData = data(machine);

export type MachineData = n.TypeOf<typeof machineData>;

export const paginatedMachineData = paginatedData(machine);

export type PaginatedMachineData = n.TypeOf<typeof paginatedMachineData>;

/******************************************************************************
 * AccessLink
 ******************************************************************************/

export const accessLink = n.record({
  id: n.string,
  email: n.string,
  host_peer_id: n.string,
  host_user_id: n.number,
  starts_at: n.string,
  expires_at: n.string,
  gamepad: n.boolean,
  keyboard: n.boolean,
  mouse: n.boolean,
  time_zone: n.string,
  event_name: n.string,
  last_accessed_at: n.nullable(n.string),
  user: n.nullable(
    n.record({
      id: n.number,
      name: n.string,
      email: n.string,
      avatar: n.string
    })
  )
});

export const accessLinkPublicMetaData = n.record({
  id: n.string,
  event_name: n.string,
  host_peer_id: n.string,
  starts_at: n.string,
  expires_at: n.string,
  time_zone: n.string
});

export type AccessLink = n.TypeOf<typeof accessLink>;

export const accessLinkData = data(accessLink);

export type AccessLinkData = n.TypeOf<typeof accessLinkData>;

export const accessLinkMetaData = data(accessLinkPublicMetaData);

export type AccessLinkMetaData = n.TypeOf<typeof accessLinkMetaData>;

export const paginatedAccessLinkData = paginatedData(accessLink);

export type PaginatedAccessLinkData = n.TypeOf<typeof paginatedAccessLinkData>;

/******************************************************************************
 * Team Key represents a generalized key which can have the type API, Computer,
 * or SCIM. This is used to store the three different key types in the same
 * data type so that they are easier to work with on the API Keys page layout.
 ******************************************************************************/

export const apiKeyActions = n.record({
  'team.app_rule.read': n.optional(n.boolean),
  'team.app_rule.members.assign': n.optional(n.boolean),
  'team.event.read': n.optional(n.boolean),
  'team.group.read': n.optional(n.boolean),
  'team.group.create': n.optional(n.boolean),
  'team.group.update': n.optional(n.boolean),
  'team.group.delete': n.optional(n.boolean),
  'team.group_permission.create': n.optional(n.boolean),
  'team.group_permission.update': n.optional(n.boolean),
  'team.group_permission.delete': n.optional(n.boolean),
  'team.group_member.add': n.optional(n.boolean),
  'team.group_member.remove': n.optional(n.boolean),
  'team.access_link_credit_balance.read': n.optional(n.boolean),
  'team.access_link.read': n.optional(n.boolean),
  'team.access_link.create': n.optional(n.boolean),
  'team.access_link.update': n.optional(n.boolean),
  'team.machine.read': n.optional(n.boolean),
  'team.machine.update': n.optional(n.boolean),
  'team.machine.delete': n.optional(n.boolean),
  'team.member.read': n.optional(n.boolean),
  'team.member.remove': n.optional(n.boolean),
  'team.invite.read': n.optional(n.boolean),
  'team.invite.create': n.optional(n.boolean),
  'team.invite.update': n.optional(n.boolean),
  'team.invite.delete': n.optional(n.boolean),
  'team.machine.kick_guest': n.optional(n.boolean),
  'team.machine.kick_user': n.optional(n.boolean)
});

export type ApiKeyActions = n.TypeOf<typeof apiKeyActions>;

export const teamKey = n.record({
  id: n.string,
  team_id: n.string,
  user_id: n.number,
  name: n.string,
  is_active: n.boolean,
  last_used_country: n.string,
  last_used_at: n.string,
  created_at: n.string,
  user: n.record({
    id: n.number,
    name: n.string,
    email: n.string,
    avatar: n.string
  }),
  type: n.number,
  actions: n.optional(apiKeyActions),
  can_delete: n.boolean,
  can_duplicate: n.boolean,
  can_toggle_enablement: n.boolean,
  can_change_name: n.boolean,
  is_old: n.boolean
});

export type TeamKey = n.TypeOf<typeof teamKey>;

/******************************************************************************
 * APIKey represents an API Key that customers use to access the Public Teams API
 ******************************************************************************/

export const apiKey = n.record({
  id: n.string,
  team_id: n.string,
  user_id: n.number,
  name: n.string,
  is_active: n.boolean,
  api_key: n.optional(n.string),
  last_used_country: n.string,
  last_used_at: n.string,
  created_at: n.string,
  user: n.record({
    id: n.number,
    name: n.string,
    email: n.string,
    avatar: n.string
  }),
  actions: n.optional(apiKeyActions)
});

export type APIKey = n.TypeOf<typeof apiKey>;

export const apiKeyData = data(apiKey);

export type APIKeyData = n.TypeOf<typeof apiKeyData>;

export const paginatedAPIKeyData = paginatedData(apiKey);

export type PaginatedAPIKeyData = n.TypeOf<typeof paginatedAPIKeyData>;

/******************************************************************************
 * Credit Balance
 ******************************************************************************/

export const creditBalance = n.record({
  credit_balance: n.number,
  credit_count: n.number
});

export type CreditBalance = n.TypeOf<typeof creditBalance>;

/******************************************************************************
 * Credit Estimate
 ******************************************************************************/

export const creditEstimate = n.record({
  credit_estimate: n.number
});

export type CreditEstimate = n.TypeOf<typeof creditEstimate>;

/******************************************************************************
 * Access Link Ledger Entry
 ******************************************************************************/

export const accessLinkLedgerEntry = n.record({
  id: n.number,
  host_email: n.string,
  host_team_id: n.string,
  client_email: n.string,
  access_link_id: n.string,
  credit_type: n.string,
  amount: n.number,
  note: n.string,
  created_at: n.string,
  updated_at: n.string
});

export type AccessLinkLedgerEntry = n.TypeOf<typeof accessLinkLedgerEntry>;

export const accessLinkLedgerEntryData = data(accessLinkLedgerEntry);

export type AccessLinkLedgerEntryData = n.TypeOf<
  typeof accessLinkLedgerEntryData
>;

export const paginatedAccessLinkLedgerEntryData = paginatedData(
  accessLinkLedgerEntry
);

export type PaginatedAccessLinkLedgerEntryData = n.TypeOf<
  typeof paginatedAccessLinkLedgerEntryData
>;

/******************************************************************************
 * TeamRolePermissionSummary
 ******************************************************************************/

export type ReadOnlyPermission =
  | 'manage_api_keys'
  | 'manage_admin_roles'
  | 'regenerate_team_computer_key'
  | 'update_team_members';

export const groupPermissions = n.record({
  assign_team_members_to_groups: n.boolean,
  invite_team_members: n.boolean,
  remove_team_members: n.boolean,
  reset_team_members_tfa: n.boolean,
  manage_team_computers: n.boolean
});

export const teamPermissions = n.record({
  assign_team_members_to_groups: n.boolean,
  download_audit_log: n.boolean,
  download_guest_access_ledger: n.boolean,
  invite_team_members: n.boolean,
  manage_admin_roles: n.boolean, // readonly
  manage_api_keys: n.boolean, // readonly
  manage_billing_info: n.boolean,
  manage_groups: n.boolean,
  assign_team_members_to_app_rules: n.boolean,
  manage_app_rules_app_config: n.boolean,
  manage_default_app_rules_app_config: n.boolean,
  manage_default_app_rules_security_config: n.boolean, // readonly
  manage_app_rules_security_config: n.boolean, // readonly
  manage_app_rules: n.boolean,
  manage_guest_access_invites: n.boolean,
  manage_security_settings: n.boolean,
  manage_subscription: n.boolean,
  manage_team_computers: n.boolean,
  purchase_guest_access_credits: n.boolean,
  regenerate_team_computer_key: n.boolean, // readonly
  remove_team_members: n.boolean,
  read_team_members: n.boolean, //readonly
  reset_team_members_tfa: n.boolean,
  update_team_info: n.boolean,
  update_team_members: n.boolean, // readonly
  manage_domains: n.boolean,
  resend_team_members_confirmation_email: n.boolean, // readonly
  manage_non_compliant_users: n.boolean,
  view_out_of_date_hosts: n.boolean,
  manage_team_relays: n.boolean
});

// groups field contains objects where the key is group_id and value is permission set
export const teamRolePermissionSummary = n.record({
  team: teamPermissions,
  groups: n.object(groupPermissions)
});

export type TeamPermissions = n.TypeOf<typeof teamPermissions>;

export type AssignableTeamPermissions = Pick<
  TeamPermissions,
  | 'assign_team_members_to_groups'
  | 'download_audit_log'
  | 'download_guest_access_ledger'
  | 'invite_team_members'
  | 'manage_billing_info'
  | 'manage_groups'
  | 'manage_guest_access_invites'
  | 'manage_security_settings'
  | 'manage_subscription'
  | 'manage_team_computers'
  | 'purchase_guest_access_credits'
  | 'remove_team_members'
  | 'reset_team_members_tfa'
  | 'manage_app_rules'
  | 'manage_default_app_rules_app_config'
  | 'manage_app_rules_app_config'
  | 'assign_team_members_to_app_rules'
  | 'manage_team_relays'
>;

const assignableTeamPermissionsKeys: Set<keyof AssignableTeamPermissions> =
  new Set<keyof AssignableTeamPermissions>([
    'assign_team_members_to_groups',
    'download_audit_log',
    'download_guest_access_ledger',
    'invite_team_members',
    'manage_billing_info',
    'manage_groups',
    'manage_guest_access_invites',
    'manage_security_settings',
    'manage_subscription',
    'manage_team_computers',
    'purchase_guest_access_credits',
    'remove_team_members',
    'reset_team_members_tfa',
    'manage_app_rules',
    'manage_default_app_rules_app_config',
    'manage_app_rules_app_config',
    'assign_team_members_to_app_rules',
    'manage_team_relays'
  ]);

export function IsAssignableTeamPermission(permission: string): boolean {
  return assignableTeamPermissionsKeys.has(
    permission as keyof AssignableTeamPermissions
  );
}

export function ToAssignableTeamPermissions(
  permissions: TeamPermissions
): AssignableTeamPermissions {
  return Object.fromEntries(
    Object.entries(permissions).filter(([key]) =>
      IsAssignableTeamPermission(key as keyof AssignableTeamPermissions)
    )
  ) as AssignableTeamPermissions;
}

export type GroupPermissions = n.TypeOf<typeof groupPermissions>;

export type AssignableGroupPermissions = Pick<
  GroupPermissions,
  | 'assign_team_members_to_groups'
  | 'invite_team_members'
  | 'remove_team_members'
  | 'reset_team_members_tfa'
  | 'manage_team_computers'
>;

const assignableGroupPermissionsKeys: Set<keyof AssignableGroupPermissions> =
  new Set<keyof AssignableGroupPermissions>([
    'assign_team_members_to_groups',
    'invite_team_members',
    'remove_team_members',
    'reset_team_members_tfa',
    'manage_team_computers'
  ]);

export function IsAssignableGroupPermission(permission: string): boolean {
  return assignableGroupPermissionsKeys.has(
    permission as keyof AssignableGroupPermissions
  );
}

export function ToAssignableGroupPermissions(
  permissions: GroupPermissions
): AssignableGroupPermissions {
  return Object.fromEntries(
    Object.entries(permissions).filter(([key]) =>
      IsAssignableGroupPermission(key as keyof AssignableGroupPermissions)
    )
  ) as AssignableGroupPermissions;
}

export type TeamRolePermissionSummary = n.TypeOf<
  typeof teamRolePermissionSummary
>;

export const teamRolePermissionSummaryData = data(teamRolePermissionSummary);

/******************************************************************************
 * TeamRole
 ******************************************************************************/

// Actions field is either a TeamPermissions or GroupPermissions model depending on whether `group_id` field is populated
export const teamRole = n.record({
  id: n.string,
  name: n.string,
  color: n.string,
  system_role: n.boolean,
  group_id: n.optional(n.number),
  actions: n.any(teamPermissions, groupPermissions)
});

export type TeamRole = n.TypeOf<typeof teamRole>;

export const teamRoleData = data(teamRole);

export type TeamRoleData = n.TypeOf<typeof teamRoleData>;

export const paginatedTeamRoleData = paginatedData(teamRole);

export type PaginatedTeamRoleData = n.TypeOf<typeof paginatedTeamRoleData>;

/******************************************************************************
 * Purchase
 ******************************************************************************/

export const purchase = n.record({
  addon_id: n.string,
  quantity: n.number,
  status: n.string
});

export type Purchase = n.TypeOf<typeof purchase>;

/******************************************************************************
 * Purchase Estimate
 ******************************************************************************/

export const estimate = n.record({
  amount_due_cents: n.number,
  subtotal_amount_cents: n.number,
  discount_amount_cents: n.number,
  tax_amount_cents: n.number,
  total_amount_cents: n.number,
  line_items: n.array(
    n.record({
      type: n.string,
      id: n.string,
      description: n.string,
      quantity: n.number,
      unit_amount_cents: n.number,
      amount_cents: n.number
    })
  ),
  tax_items: n.array(
    n.record({
      name: n.string,
      description: n.string,
      amount_cents: n.number
    })
  ),
  discount_items: n.nullable(
    n.array(
      n.record({
        id: n.string,
        description: n.string,
        amount_cents: n.number
      })
    )
  ),
  total_credit_notes_applied_cents: n.number,
  total_proration_amount_cents: n.number,
  next_billing_at: n.string,
  next_renewal: n.record({
    subtotal_amount_cents: n.number,
    discount_amount_cents: n.number,
    tax_amount_cents: n.number,
    total_amount_cents: n.number
  })
});

export type Estimate = n.TypeOf<typeof estimate>;

/******************************************************************************
 * Team Events
 ******************************************************************************/

export type TeamEvent = {
  id: string;
  type?: string;
  name: string;
  timestamp: Date;
  user_id: number;
  team_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user_email?: any;
};

/******************************************************************************
 * TFA
 ******************************************************************************/

export const tfa = n.record({
  recovery_email: n.string
});

export type TFA = n.TypeOf<typeof tfa>;

/******************************************************************************
 * TFA Backup Code
 ******************************************************************************/

export const tfaBackupCode = n.record({
  code: n.string,
  used: n.boolean
});

export type TFABackupCode = n.TypeOf<typeof tfaBackupCode>;

/******************************************************************************
 * Invoice
 ******************************************************************************/

export const invoice = n.record({
  id: n.string,
  token: n.string,
  description: n.array(n.string),
  po_number: n.optional(n.string),
  status: n.any(
    n.literal('paid'),
    n.literal('posted'),
    n.literal('payment_due'),
    n.literal('not_paid'),
    n.literal('voided'),
    n.literal('pending')
  ),
  date: n.string,
  total_amount_cents: n.number,
  sub_total_amount_cents: n.number,
  total_amount_due_cents: n.number
});

export type Invoice = n.TypeOf<typeof invoice>;

/******************************************************************************
 * Game
 ******************************************************************************/

export const game = n.record({
  id: n.string,
  image_url: n.string,
  name: n.string,
  portrait_image_url: n.string
});

export type Game = n.TypeOf<typeof game>;

/******************************************************************************
 * Game Activation
 ******************************************************************************/

export const gameActivation = n.record({
  game,
  expires_at: n.number,
  status: n.any(
    n.literal('pending'),
    n.literal('approved'),
    n.literal('denied'),
    n.literal('used')
  )
});

export type GameActivation = n.TypeOf<typeof gameActivation>;

/******************************************************************************
 * Changelog
 ******************************************************************************/

export const changelogRss = n.record({
  body: n.string
});

export type ChangelogRss = n.TypeOf<typeof changelogRss>;

/******************************************************************************
 * Domains
 ******************************************************************************/

export const domain = n.record({
  id: n.string,
  team_id: n.string,
  domain: n.string,
  is_verified: n.boolean,
  txt_record: n.string,
  updated_at: n.string,
  created_at: n.string
});

export type Domain = n.TypeOf<typeof domain>;
export const domainList = n.nullable(n.array(domain)); // not paginated
export type DomainList = n.TypeOf<typeof domainList>;

/******************************************************************************
 * SCIM API Key
 ******************************************************************************/

export const scimApiKey = n.record({
  id: n.string,
  team_id: n.string,
  user_id: n.number,
  raw_key: n.optional(n.string),
  created_at: n.string,
  last_used_country: n.string,
  last_used_at: n.string,
  user: n.record({
    id: n.number,
    name: n.string,
    email: n.string,
    avatar: n.string
  })
});

export type ScimApiKey = n.TypeOf<typeof scimApiKey>;

export const scimApiKeyData = data(scimApiKey);

/******************************************************************************
 * Machine Key
 ******************************************************************************/

export const teamMachineKey = n.record({
  id: n.string,
  team_id: n.string,
  user_id: n.number,
  created_at: n.string,
  last_used_country: n.string,
  last_used_at: n.string,
  user: n.record({
    id: n.number,
    name: n.string,
    email: n.string,
    avatar: n.string
  })
});

export type TeamMachineKey = n.TypeOf<typeof teamMachineKey>;

export const teamMachineKeyData = data(teamMachineKey);

/******************************************************************************
 * Out of Date Hosts
 ******************************************************************************/

export const outOfDateHost = n.record({
  id: n.string,
  peer_id: n.string,
  is_guest_access: n.boolean,
  is_online: n.boolean,
  name: n.string
});

export const outOfDateHosts = n.record({
  data: n.nullable(n.array(outOfDateHost)),
  count: n.number
});

/******************************************************************************
 * Team Relay
 ******************************************************************************/

export const teamRelay = n.record({
  id: n.string,
  team_id: n.string,
  name: n.string,
  stun_address: n.string,
  stun_port: n.number,
  relay_port: n.number,
  disable_new_connections: n.boolean,
  last_heartbeat_received_at: n.string,
  connection_metrics: n.record({
    connection_count: n.optional(n.number),
    relay_version: n.optional(n.string)
  }),
  created_at: n.string
});

export type TeamRelay = n.TypeOf<typeof teamRelay>;
export const teamRelayList = n.nullable(n.array(teamRelay)); // not paginated
export type TeamRelayList = n.TypeOf<typeof teamRelayList>;
export const teamRelaySecret = n.record({ secret: n.string });

/******************************************************************************
 * Application Downtime
 ******************************************************************************/
export const downtime = n.record({
  display: n.optional(n.boolean),
  type: n.optional(n.string),
  title: n.optional(n.string),
  message: n.optional(n.string),
  link_title: n.optional(n.string),
  link_url: n.optional(n.string)
});

export type Downtime = n.TypeOf<typeof downtime>;

/******************************************************************************
 * Maintenance Banner
 ******************************************************************************/
export const maintenance = n.any(
  n.record({
    display: n.literal(true), // show banner, other field required if true
    title: n.string, // banner title
    message: n.string, // banner body
    link_title: n.string, // text for the button
    link_url: n.string, // url for the button
    // optional fields even if display is false
    expiration_time: n.optional(n.number), // unix timestamp / number, defines a specific time to stop showing the banner
    poll_period_m: n.optional(n.number) // number of minutes between polls, defaults to 6 hours
  }),
  n.record({
    display: n.any(n.literal(false), n.literal('false'), n.literal('true')), // treat all values not true as false (including strings), no other fields are required
    title: n.optional(n.string), // banner title
    message: n.optional(n.string), // banner body
    link_title: n.optional(n.string), // text for the button
    link_url: n.optional(n.string), // url for the button
    // optional fields even if display is false
    expiration_time: n.optional(n.number), // unix timestamp / number, defines a specific time to stop showing the banner
    poll_period_m: n.optional(n.number) // number of minutes between polls, defaults to 6 hours
  })
);

export type Maintenance = n.TypeOf<typeof maintenance>;
